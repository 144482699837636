import {Action, Selector, State, StateContext} from '@ngxs/store';
import {inject, Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {ProfileService} from '../../modules/profile/profile.service';
import {groupBy} from 'lodash';
import {ItemPayload} from 'src/app/interfaces/general/responses/item-payload.interface';
import {IPartnerData} from '../../interfaces/general/profile-definitions/partner-data.interface';
import {GetTrophies} from './trophy.actions';
import {errorHandler, IErrorHandlerArgs} from '../../shared/helpers/error-handler';
import {AppInsightsService} from '../../core/app-insights/app-insights.service';

export interface ITrophyState {
    data: Partial<MappedTrophies>;
    loading: boolean;
    hasValue: boolean;
    error: string | null;
}

type MappedTrophies = {
    [key: string]: {
        imageUrl: string | number | {[x: string]: any};
        points: string | number | {[x: string]: any};
    }[];
};

@State<ITrophyState>({
    name: 'trophies',
    defaults: {
        data: {},
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class TrophyState {
    private readonly insights = inject(AppInsightsService);
    private readonly profileService = inject(ProfileService);
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'TrophyState',
    };

    @Selector()
    public static data(state: ITrophyState): Partial<MappedTrophies> {
        return state.data;
    }

    @Selector()
    public static loading(state: ITrophyState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: ITrophyState): boolean {
        return state.hasValue;
    }

    @Action(GetTrophies)
    public async getTrophies(ctx: StateContext<ITrophyState>): Promise<any> {
        const state = ctx.getState();
        ctx.patchState({loading: true});
        let data: Partial<MappedTrophies>;
        try {
            if (state.hasValue) {
                data = state.data;
            } else {
                const trophies = await firstValueFrom(this.profileService.getPartnerData(10000, 'Trophy'));
                data = this.mapTrophies(trophies);
            }
            ctx.patchState({
                data,
                hasValue: Object.keys(data).length > 0,
                loading: false,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                hasValue: false,
                loading: false,
                error: error?.message ?? error,
            });
        }
    }

    private mapTrophies(trophies: ItemPayload<IPartnerData>): Partial<MappedTrophies> {
        const groupedTrophies = groupBy(trophies.data, (item) => {
            return item.dataGovernance.origin[0].source.acronym;
        });
        const mappedTrophies: Partial<MappedTrophies> = {};
        for (const key in groupedTrophies) {
            mappedTrophies[key] = groupedTrophies[key].map((trophy) => {
                return {
                    imageUrl: trophy.additionalProperty.find(
                        (prop) => prop.propertyId === 'imageUrl',
                    )?.value,
                    points: trophy.additionalProperty.find((prop) => prop.propertyId === 'points')
                        ?.value,
                };
            });
        }
        return mappedTrophies;
    }
}
